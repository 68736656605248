
export const Persistent = {

    set(key, value) {
        localStorage.setItem(key, value)
    },

    get(key) {
        return localStorage.getItem(key);
    }

}


export const Session = {

    set(key, value) {
        sessionStorage.setItem(key, JSON.stringify(value))
    },

    add(key, value) {
        let list = this.get(key);   
        if(!list){
            list = [];
        }
        list.push(value);
        sessionStorage.setItem(key, JSON.stringify(list))
    },

    get(key) {
        return JSON.parse(sessionStorage.getItem(key));
    }

}

export default {
    Persistent,
    Session
};