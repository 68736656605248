import React from 'react';
import Button from '../components/Button';
import Input from '../components/Input';
import { Session } from '../utils/storage'

class Start extends React.Component {

    state = {
        player: "player",
        optionOneTimesOne: true,
            oneTimesOneValues: [1,2,3,4,5,6,7,8,9,10],
            oneTimesOneSymbols: ["*","/"],
        optionMinusPlus: false,
            minusPlusValues: [500],
            minusPlusSymbols: ["+","-"],
        optionOther: false,
    }

    setNameOfPlayer = (event) => {
        this.setState({ player: event.target.value })
    }

    setAllOptionFalse = (event) => {
        this.setState({ 
          optionOneTimesOne:false ,
          optionMinusPlus: false,
          optionOther:false
        })
    }

    handleSingleListChange = (event, listKey, number) => {   
        let tmp = [];
        if(event.target.checked) {
            tmp.push(number);
        } 
        let obj = {};
        obj[listKey]=tmp;
        
        this.setState(obj)
    }

    handleListChange = (event, listKey, number) => {   
        let tmp = this.state[listKey];
        if(event.target.checked) {
            tmp.push(number);
        } else{
            const index = tmp.indexOf(number);
            if (index > -1) {
                tmp.splice(index, 1);
            }
        }
        let obj = {};
        obj[listKey]=tmp;
        
        this.setState(obj)
    }

    listContains = (listKey, number) => {     
      return this.state[listKey] && this.state[listKey].includes(number);
    }

    clicked = () => {
        if(this.state.optionOneTimesOne){
            Session.set("option", {
                type: "OneTimesOne",
                values: this.state.oneTimesOneValues,
                symbols: this.state.oneTimesOneSymbols
            });
        }else if(this.state.optionMinusPlus){
            Session.set("option", {
                type: "MinusPlus",
                values: this.state.minusPlusValues,
                symbols: this.state.minusPlusSymbols
            });
        }

        Session.set("correct",[])
        Session.set("wrong", [])
        Session.set("math", this.state.player)
        this.props.startPressed();
    }



    render (){

        return (
            <div>
                <div className="App-brandname">
                    <i className="fas fa-graduation-cap"></i> 
                    <br/>    
                    <h3>Do You Know</h3>     
                    <h1>Math?</h1>
                </div>
                <Input text="Namen eingeben" onInputChange={this.setNameOfPlayer} />
            
                <div className="radio">
                <br/>                 
                <h1><label><input type="radio" checked={this.state.optionOneTimesOne} onChange={(e) => {this.setState({ optionOneTimesOne: e.target.checked, optionMinusPlus: false })}} />Kleines 1x1</label></h1>
                <div style={{ display: this.state.optionOneTimesOne ? undefined : 'none'}}>
                    <div ><label><input type="checkbox" checked={this.listContains("oneTimesOneSymbols","*")} onChange={(e) => this.handleListChange(e,"oneTimesOneSymbols", "*")}/>Mal</label><br/></div>
                   <div ><label><input type="checkbox" checked={this.listContains("oneTimesOneSymbols","/")} onChange={(e) => this.handleListChange(e,"oneTimesOneSymbols", "/")}/>Geteilt</label><br/></div>
                    <br/>
                    {[...Array(10).keys()].map((value, index) => {
                        return<div key={value}><label><input type="checkbox" checked={this.listContains("oneTimesOneValues", value+1)} onChange={(e) => this.handleListChange(e, "oneTimesOneValues", value+1)}/> 1x{value+1}</label><br/></div>
                    })}
                </div>
                </div>
                <div className="radio">
                <br/>  
                <h1><label><input type="radio" checked={this.state.optionMinusPlus} onChange={(e) => { this.setState({ optionMinusPlus: e.target.checked, optionOneTimesOne: false })}} />Plus/Minus</label></h1>
                   
                <div style={{ display: this.state.optionMinusPlus ? undefined : 'none'}}>
                    <div ><label><input type="checkbox" checked={this.listContains("minusPlusSymbols","+")} onChange={(e) => this.handleListChange(e,"minusPlusSymbols","+")}/>Plus</label><br/></div>
                    <div ><label><input type="checkbox" checked={this.listContains("minusPlusSymbols","-")} onChange={(e) => this.handleListChange(e,"minusPlusSymbols", "-")}/>Minus</label><br/></div>
                    <br/>
                    <div><label><input type="radio" checked={this.listContains("minusPlusValues", 100)} onChange={(e) => this.handleSingleListChange(e, "minusPlusValues", 100)}/> bis 100</label><br/></div>
                    <div><label><input type="radio" checked={this.listContains("minusPlusValues", 500)} onChange={(e) => this.handleSingleListChange(e, "minusPlusValues", 500)}/> bis 500</label><br/></div>
                    <div><label><input type="radio" checked={this.listContains("minusPlusValues", 1000)} onChange={(e) => this.handleSingleListChange(e, "minusPlusValues", 1000)}/> bis 1000</label><br/></div>
                    <div><label><input type="radio" checked={this.listContains("minusPlusValues", 10000)} onChange={(e) => this.handleSingleListChange(e, "minusPlusValues", 10000)}/> bis 10000</label><br/></div>
                </div>
                </div>
                <p>
                    Press to start the game
                </p>
                <Button isClicked={this.clicked}>Start</Button>
                
            </div>
        )
    }
}
export default Start;