
const Colors = {
    yellow: "#d8b600",
    lightBlue: "#61dafb",
    darkGray: "#999ea7",
    midGray: "#424855",
    lightGray: "#f5f5f5",
    darkBg: "#282c34",
    red:"#ff6572",
    green: "#31cd61"
}

export default Colors